<template>
  <div>

    <section-add-item
      :group="getCurrentCategories()[getDraftItem().group]"
      :category="getCurrentCategories()[getDraftItem().group].categories[getDraftItem().category]"
      :subcategory="getCurrentCategories()[getDraftItem().group].categories[getDraftItem().category].subcategories[getDraftItem().subcategory]"
      :categories="getCurrentCategories()"
      @go-to-table="goToTable()"
    />

  </div>
</template>
<script>
import SectionAddItem from '@/views/items/items/items-store/SectionAddItem.vue'
import useItemList from '@/views/items/items/useItemList'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'ItemStore',
  components: {
    SectionAddItem,
  },

  setup() {
    const {
      fetchItems,
      getDraftItem,
      refetchData,
    } = useItemList()

    const {
      fetchCategories,
      getCurrentCategories,
    } = useCategoriesList()

    return {
      fetchItems,
      getDraftItem,
      fetchCategories,
      refetchData,
      getCurrentCategories,
    }
  },
  created() {
    if (this.getDraftItem().length === 0) {
      this.$router.push({ name: 'items.list' })
    }
  },
  methods: {
    goToTable() {
      this.$router.push({ name: 'items.list' })
    },
    next() {
      this.$refs.wizard.nextTab()
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
