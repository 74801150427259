<template>
  <div v-if="getCurrentTypes() && filtersData">
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-form
        ref="form"
        :style="{height: trHeight}"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
        @click="submitType = $event"
      >
        <b-row class="match-height">
          <b-col
            lg="6"
            md="6"
          >
            <b-card>
              <b-row>
                <b-col
                  md="12"
                >
                  <div class="d-flex justify-content-start breadcrumb-wrapper pl-0">
                    <b-card-title>
                      {{ filtersData.group.name.it }} > {{ filtersData.category.name.it }} > {{ filtersData.subcategory.name.it }} | {{ filtersData.group.name.en }} > {{ filtersData.category.name.en }} > {{ filtersData.subcategory.name.en }}
                    </b-card-title>
                  </div>
                </b-col>
                <b-col
                  md="4"
                >
                  <!-- Code -->
                  <validation-provider
                    #default="validationContext"
                    name="code"
                    rules="required|digits:5"
                  >
                    <b-form-group
                      :label="'Code * : '+filtersData.category.custom_code+itemData.code"
                      label-for="code"
                    >
                      <b-form-input
                        id="code"
                        v-model="itemData.code"
                        placeholder="Code"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="4">

                  <!-- Status -->
                  <validation-provider
                    #default="validationContext"
                    name="status"
                    rules="required"
                  >
                    <b-form-group
                      label="Status *"
                      label-for="status"
                    >
                      <div class="form-label-group">
                        <v-select
                          v-model="itemData.status"
                          :options="status"
                          :reduce="val => val.value"
                          input-id="status"
                          :clearable="false"
                        >
                          <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :required="itemData.status == null"
                              v-bind="attributes"
                              v-on="events"
                            >
                          </template>
                        </v-select>
                      </div>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="12">
                  <!-- Title EN -->
                  <validation-provider
                    #default="validationContext"
                    name="Title EN"
                    rules="required"
                  >
                    <b-form-group
                      label="Title EN *"
                      label-for="title_en"
                    >
                      <b-form-input
                        id="title_en"
                        v-model="itemData.title.en"
                        v-b-tooltip.hover.v-primary
                        placeholder="Title EN"
                        title="period-specification-item-material-colors"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="12">

                  <!-- Title IT -->
                  <validation-provider
                    #default="validationContext"
                    name="Title IT"
                    rules="required"
                  >
                    <b-form-group
                      label="Title IT *"
                      label-for="title_it"
                    >
                      <b-form-input
                        id="title_it"
                        v-model="itemData.title.it"
                        v-b-tooltip.hover.v-primary
                        placeholder="Title IT"
                        title="prodotto-specifica-materiali-colori-periodo"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

              </b-row>
            </b-card>
          </b-col>
          <b-col
            lg="6"
            md="6"
          >
            <b-card>
              <b-row>
                <b-col
                  md="12"
                >
                  <b-card-title>General Info</b-card-title>
                </b-col>
                <b-col md="6">

                  <!-- Period -->
                  <validation-provider
                    #default="validationContext"
                    name="period"
                    rules=""
                  >
                    <b-form-group
                      label="Period"
                      label-for="period"
                    >
                      <v-select
                        v-if="periodsOptions"
                        v-model="itemData.year"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        taggable
                        push-tags
                        placeholder="Add Period"
                        input-id="period"
                        :reduce="val => val.label"
                        :options="periodsOptions"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="6">

                  <!-- Brand -->
                  <validation-provider
                    #default="validationContext"
                    name="brand"
                    rules=""
                  >
                    <b-form-group
                      label="Brand"
                      label-for="brand"
                    >
                      <v-select
                        v-if="brandsOptions"
                        v-model="itemData.brand"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        taggable
                        push-tags
                        placeholder="Add Brand"
                        input-id="brand"
                        :reduce="brandsOptions.length > 0 ? val => val.label : val => val"
                        :options="brandsOptions"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col md="6">

                  <!-- Size -->
                  <validation-provider
                    #default="validationContext"
                    name="size"
                    rules=""
                  >
                    <b-form-group
                      label="Size"
                      label-for="size"
                    >

                      <v-select
                        v-if="sizesOptions"
                        v-model="itemData.size"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        placeholder="Size"
                        input-id="size"
                        :reduce="val => val.label"
                        :options="sizesOptions"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="6">

                  <!-- Gender -->
                  <validation-provider
                    #default="validationContext"
                    name="gender"
                    rules=""
                  >
                    <b-form-group
                      label="Gender"
                      label-for="gender"
                    >
                      <div class="form-label-group">
                        <v-select
                          v-model="itemData.gender"
                          :options="genders"
                          :reduce="val => val.value"
                          input-id="gender"
                        />
                      </div>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="6">

                  <!-- Location -->
                  <validation-provider
                    #default="validationContext"
                    name="location"
                    rules=""
                  >
                    <b-form-group
                      label="Location"
                      label-for="location"
                    >

                      <v-select
                        v-if="locationsOptions"
                        v-model="itemData.location"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        taggable
                        push-tags
                        placeholder="Add Location"
                        input-id="location"
                        :reduce="locationsOptions.length > 0 ? val => val.label : val => val"
                        :options="locationsOptions"
                      />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="6" />
                <b-col
                  md="4"
                  sm="6"
                  class="mt-2"
                >
                  {{ itemData.special_price ? 'Special item' : itemData.gems ? 'Gems item' : 'Normal item' }}
                  <strong>€ {{ itemData.special_price ? itemData.special_price : itemData.gems ? filtersData.category.gems_price : filtersData.category.price }}</strong>
                </b-col>
                <b-col
                  md="2"
                  sm="6"
                >
                  <label>{{ itemData.gems ? 'Gems Price' : 'Price' }}</label>

                  <b-form-checkbox
                    v-model="itemData.gems"
                    checked="true"
                    class="custom-control-success"
                    name="multiple"
                    switch
                    @change="itemData.special_price = null"
                  >
                    <span class="switch-icon-left">
                      G
                    </span>
                  </b-form-checkbox>
                </b-col>
                <b-col md="6">

                  <!-- Special Price -->
                  <validation-provider
                    #default="validationContext"
                    name="special_price"
                    rules="regex:^([0-9]+)$"
                  >
                    <b-form-group
                      label="Special Price"
                      label-for="special_price"
                    >
                      <b-input-group
                        prepend="€"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="special_price"
                          v-model="itemData.special_price"
                          v-b-tooltip.hover.v-warning
                          title="Apply this price only if it is a special product"
                          placeholder="Special Price"
                          :state="getValidationState(validationContext)"
                          @input="itemData.gems = false"
                        />

                      </b-input-group>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

              </b-row>
            </b-card>
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <b-card v-if="getCurrentTypes() && filtersData">
              <b-row
                v-for="(cat, index) in filtersData"
                :key="cat.id"
              >

                <b-col
                  v-if="index === 'category' && cat.types.length > 0"
                  md="12"
                >
                  <b-card-title>Category Filters</b-card-title>
                </b-col>
                <b-col
                  v-else-if="index === 'subcategory' && cat.types.length > 0"
                  md="12"
                  class="mt-2"
                >
                  <b-card-title>Sub Category Filters</b-card-title>
                </b-col>

                <template v-if="cat.types && cat.types.length > 0">
                  <b-col
                    v-for="(type) in cat.types"
                    :key="type.id"
                    md="4"
                  >

                    <b-form-group
                      v-if="getCurrentTypes()[type]"
                      :label="getCurrentTypes()[type].name.en"
                      :label-for="getCurrentTypes()[type].name.en"
                    >
                      <div class="form-label-group">
                        <v-select
                          v-model="itemData.filters[type]"
                          :options="resolveDataForVSelect(type)"
                          :reduce="val => val.value"
                          :input-id="getCurrentTypes()[type].name.en"
                          :multiple="getCurrentTypes()[type].multiple === 1"
                          :required="getCurrentTypes()[type].required === 1"
                        >
                          <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :required="getCurrentTypes()[type].required === 1 && (itemData.filters[type] == null || itemData.filters[type].length === 0) && resolveDataForVSelect(type).length > 0"
                              v-bind="attributes"
                              v-on="events"
                            >
                          </template>
                        </v-select>
                      </div>
                    </b-form-group>

                  </b-col>
                </template>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            lg="12"
            md="12"
          >
            <b-card
              no-body
              class="card-employee-task"
            >
              <b-card-header>
                <b-card-title>
                  Damages
                </b-card-title>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add New</span>
                </b-button>
              </b-card-header>
              <b-card-body>
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in itemData.damages"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <!-- Item Name -->
                  <b-col>
                    <b-row>
                      <b-col md="2">
                        <b-form-group>
                          <label>
                            Date
                          </label>
                          <flat-pickr
                            v-model="itemData.damages[index].date"
                            class="form-control"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Cost -->
                      <b-col md="10">
                        <b-form-group
                          label="Description"
                          label-for="description"
                        >
                          <b-form-textarea
                            v-model="itemData.damages[index].description"
                            no-resize
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col
                        v-if="itemData.damages.length > 1"
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          size="sm"
                          @click="removeItem(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>Delete</span>
                        </b-button>
                      </b-col>
                      <b-col v-if="itemData.damages.length > 1">
                        <hr>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            lg="9"
            md="6"
          >
            <b-card
              no-body
              class="card-employee-task"
            >
              <b-card-header>
                <b-card-title>
                  Notes
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <!-- Row Loop -->
                <b-row>
                  <!-- Item Name -->
                  <b-col>
                    <b-row>
                      <!-- Cost -->
                      <b-col md="12">
                        <b-form-group>
                          <b-form-textarea
                            v-model="itemData.notes"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            lg="3"
            md="2"
            class="d-block"
          >
            <b-card>
              <b-row>
                <!-- submit and reset -->
                <b-col
                  md="12"
                  class="mb-2"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="submit"
                    variant="primary"
                    name="new"
                    class="btn-block"
                    @click="toDo = 'new'"
                  >
                    <feather-icon
                      icon="RotateCcwIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Save and create new in {{ filtersData.subcategory.name.en }}</span>
                  </b-button>
                </b-col>
                <b-col
                  md="12"
                  class="mb-2"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="submit"
                    variant="secondary"
                    name="table"
                    class="btn-block"
                    @click="toDo = 'table'"
                  >
                    <feather-icon
                      icon="LogOutIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Save and return to the table</span>
                  </b-button>
                </b-col>
                <b-col
                  v-if="$can('store', 'Gallery')"
                  md="12"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="submit"
                    variant="outline-primary"
                    name="images"
                    class="btn-block"
                    @click="toDo = 'images'"
                  >
                    <feather-icon
                      icon="ImageIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Save and upload the images</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormInvalidFeedback, BFormTextarea, BInputGroup, VBTooltip, BCard, BCardHeader, BCardBody, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import useItemList from '@/views/items/items/useItemList'
import useTypesList from '@/views/items/filters/filters-list/useTypesList'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import store from '@/store'
import { heightTransition } from '@core/mixins/ui/transition'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SectionInserimnetoItem',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormInvalidFeedback,
    BFormTextarea,
    BInputGroup,
    vSelect,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCard,
    BFormCheckbox,

    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    group: {
      type: Object,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    subcategory: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    // Use toast
    const toast = useToast()

    const {
      fetchItems,
      getDraftItem,
      genders,
      status,
      periodsOptions,
      brandsOptions,
      locationsOptions,
      sizesOptions,
    } = useItemList()

    fetchItems()

    const blankFiltersData = {
      group: props.group,
      category: props.category,
      subcategory: props.subcategory,
    }

    const blankItemData = {
      subcategory_id: props.subcategory.id,
      code: '',
      title: {
        en: null,
        it: null,
      },
      year: '',
      brand: '',
      size: '',
      special_price: '',
      gems: false,
      gender: '',
      location: '',
      status: 'draft',
      filters: {},
      damages: [{
        id: 0,
        date: null,
        description: null,
      }],
      notes: null,
      manual_code: 1,
    }

    const filtersData = ref(JSON.parse(JSON.stringify(blankFiltersData)))
    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetitemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
    }

    const submitType = ref(null)
    const toDo = ref(null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetitemData)

    const onSubmit = () => {
      store.dispatch('items/addItem', itemData.value)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          if (toDo.value === 'new') {
            resetForm()
          } else if (toDo.value === 'images') {
            root.$router.push({ name: 'items.edit', params: { id: response.data.data.id }, hash: '#gallery' })
          } else {
            emit('refetch-data')
            emit('go-to-table')
          }
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      fetchTypes,
      getCurrentTypes,
      mapbyid,
    } = useTypesList()

    const {
      fetchCategories,
      getCurrentCategories,
    } = useCategoriesList()

    return {
      toDo,
      periodsOptions,
      brandsOptions,
      locationsOptions,
      sizesOptions,
      fetchItems,

      // Validation
      refFormObserver,
      getValidationState,
      resetForm,
      //
      // Types
      fetchTypes,
      getCurrentTypes,
      mapbyid,
      //

      submitType,
      onSubmit,
      itemData,
      filtersData,
      fetchCategories,
      getCurrentCategories,
      getDraftItem,
      genders,
      status,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
      nextTodoId: 0,
    }
  },
  mounted() {
    this.mapbyid = true
    this.fetchTypes()
    this.fetchCategories()
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    buildCategoriesForSelect(array) {
      const newArray = []
      array.forEach(key => {
        newArray.push({
          value: key.value,
          title: key.label.en,
        })
      })
      return newArray
    },
    resolveDataForVSelect(index) {
      return this.getCurrentTypes()[index].filters.map(data => ({
        label: `${data.name.it} - ${data.name.en}`,
        value: data.id,
      }))
    },

    repeateAgain() {
      this.itemData.damages.push({
        id: this.nextTodoId += 1,
        date: null,
        description: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.itemData.damages.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
